<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-data-table
      :headers="headers"
      :items="listProducts"
      :loading="isLoadingProducts"
      :items-per-page="30"
      show-expand
      single-expand
      :footer-props="{ 'items-per-page-options': [5, 30, 50] }"
    >
      <template v-slot:item.image="{ item }">
        <v-img
          v-if="item.image"
          :src="item.image.url"
          max-height="50"
          max-width="50"
        />
        <v-img
          v-else
          src="@/assets/product.png"
          max-height="50"
          max-width="50"
        />
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showItem(item)"> mdi-eye </v-icon>
      </template>
      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("products.products") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <show-product></show-product>
          <v-btn
            color="primary"
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            <v-icon color="white" class="mr-2">
              mdi-arrow-down-bold-circle-outline
            </v-icon>
            {{ $t("export_result_csv") }}
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="5">
            <v-autocomplete
              clearable
              v-model="options.provider_id"
              :items="providers"
              item-text="company_name"
              item-value="id"
              :label="$t('provider')"
              :search-input.sync="search_provider"
              :loading="isLoadingProviders"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              clearable
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="isLoadingHubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <!-- <v-col cols="12" sm="3" md="3">
                    <v-menu v-model="menu_date_start" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="options.start_date" :label="$t('start_date')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="options.start_date"
                            no-title
                            color="green lighten-1"
                            header-color="green lighten-1"
                            @input="menu_date_start = false"
                     ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                    <v-menu v-model="menu_date_end" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto" >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="options.end_date" :label="$t('end_date')" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="options.end_date"
                            no-title
                            color="premary"
                            @input="menu_date_end = false"
                     ></v-date-picker>
                    </v-menu>
                </v-col> -->
          <v-col cols="12" sm="3">
            <v-btn
              block
              color="primary"
              @click="filterInbound"
              :loading="isLoadingProducts"
              :disabled="isLoadingProducts"
            >
              <v-icon color="white" class="mr-2"> mdi-filter </v-icon>
              {{ $t("filter") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table class="my-5" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("name") }}
                  </th>

                  <th class="text-left">
                    {{ $t("quantity") }}
                  </th>

                  <th class="text-left">
                    {{ $t("min_quantity") }}
                  </th>
                  <th class="text-left">
                    {{ $t("max_quantity") }}
                  </th>
                  <th class="text-left">
                    {{ $t("qte_in_orders") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="hub in item.hubs" :key="hub.id">
                  <td>{{ hub.name }}</td>
                  <td>{{ hub.qte_in_hub }}</td>
                  <td>{{ hub.min_qte_in_hub }}</td>
                  <td>{{ hub.max_qte_in_hub }}</td>
                  <td>{{ hub.qte_in_orders }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    ShowProduct: () => import("./product-dialogs/Show.vue"),
  },

  computed: {
    ...mapGetters({
      isLoadingProviders: "providers/isLoadingProviders",
      providers: "providers/listProviders",
      isLoadingHubs: "hubs/isLoadingHubs",
      hubs: "hubs/listHubs",

      isLoadingProducts: "statistics/isLoadingData",
      listProducts: "statistics/listProducts",
      productsMeta: "statistics/meta",
    }),
  },

  watch: {
    search_provider: debounce(function (search) {
      if (this.isLoadingProviders) return;

      this.$store.dispatch("providers/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),

    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  data() {
    return {
      is_loading_csv: false,
      menu_date_start: false,
      menu_date_end: false,
      options: { type: "inbound" },
      search_provider: "",
      search_hub: "",

      headers: [
        {
          text: this.$t("sku"),
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: this.$t("product_name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("quantity"),
          value: "inbound_qte",
        },

        {
          text: this.$t("purchase_price_ht"),
          value: "purchase_price_ht",
        },
        {
          text: this.$t("purchase_tva"),
          value: "purchase_tva",
        },

        {
          text: this.$t("price_ht"),
          value: "price_ht",
        },
        {
          text: this.$t("tva"),
          value: "tva",
        },

        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    showItem(item) {
      this.$store.dispatch("products/openShowForm", item);
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },

    async getCSV(option) {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("statistics/exportCSV", option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Inbound_products.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_invoice = false;
        });
    },

    async filterInbound() {
      await this.$store.dispatch("statistics/list", this.options);
    },
  },
};
</script>
<style>
.v-data-table .v-toolbar__content {
  padding-right: 0;
}
</style>
